.instagram-container {
  margin: 0 auto;
  width: 100%;
}

.instagram-media {
  background: #FFF;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15);
  margin: 0 auto;
  min-width: 326px;
  padding: 0;
  width: 100%;
}

.instagram-link {
  background: #FFFFFF;
  line-height: 0;
  padding: 0 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
